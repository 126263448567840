<template>
  <div :class="variant">
    <slot name="label">
      <label
        v-if="label"
        :class="labelClass"
      >{{ label }}
      </label>
    </slot>
    <div
      class="relative"
      :class="inputContainerClass"
    >
      <base-input
        :model-value="inputValue"
        :disabled="disabled"
        v-bind="inputProps"
        @update:modelValue="updateInputValue($event)"
      />
      <transition
        name="Dropdown-transition"
        enter-from-class=""
        enter-active-class=""
        enter-to-class=""
        leave-from-class="opacity-100"
        leave-active-class="transition ease-in duration-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="!disableSuggestions && inputValue && filteredOptions.length && dropdownVisibility"
          class="absolute mt-1 w-full bg-white z-10"
          :class="dropdownContainerClass"
        >
          <ul
            tabindex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-item-3"
            class="focus:outline-none overflow-auto sm:text-sm dropdown"
            :class="dropdownClass"
          >
            <li
              v-for="(option, oIndex) in filteredOptions"
              :id="'listbox-item-' + oIndex"
              :key="'option' + oIndex"
              role="option"
              class="list-item"
              :class="[
                inputValue === option ? 'selected-item ' + selectedItemClass : '',
                listItemClass
              ]"
              @click="selectOption(option)"
            >
              <div class="flex items-center">
                <span
                  class="ml-3 block truncate"
                >
                  {{ option }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue';

export default {
    name: 'BaseAutoCompleteInput',

    components: {
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue'))
    },

    props: {
        options: {
            type: Array,
            default: () => [],
            description: 'Options for dropdown'
        },
        modelValue: {
            type: [String, Number],
            default: '',
            description: '2-way bound value'
        },
        disabled: {
            type: Boolean,
            default: false,
            description: 'disables component if set to true'
        },
        disableSuggestions: {
            type: Boolean,
            default: false,
            description: 'hide dropdown suggestions'
        },
        inputContainerClass: {
            type: [Object, String, Array],
            default: 'h-full w-full',
            description: 'classes applied to base-input container'
        },
        inputProps: {
            type: Object,
            default: () => {},
            description: 'Props object for base-input(check base-input component for supported props)'
        },
        dropdownContainerClass: {
            type: [Object, String, Array],
            default: 'shadow-lg'
        },
        dropdownClass: {
            type: [Object, String, Array],
            default: '',
            description: 'css classes appended to dropdown menu'
        },
        listItemClass: {
            type: String,
            default: '',
            description: 'css classes appended to each item in list'
        },
        selectedItemClass: {
            type: String,
            default: '',
            description: 'css classes appended to selected item in list'
        },
        label: {
            type: String,
            default: '',
            description: 'label text for input(for custom label, use slot)'
        },
        labelClass: {
            type: String,
            default: 'label',
            description: 'css classes applied to label'
        },
        variant: {
            type: String,
            default: 'autocomplete-default',
            description: ''
        }
    },

    emits: [
        'update:modelValue',
        'optionSelected'
    ],

    setup (props, { emit, slots }) {
        // 2-way binding logic
        const inputValue = ref('');
        onMounted(() => {
            initInputValue();
        });
        watch(
            () => props.modelValue,
            () => {
                initInputValue();
            }
        );
        const initInputValue = () => {
            if (inputValue.value !== props.modelValue) {
                inputValue.value = props.modelValue;
            }
        };
        const handleUpdate = (newValue) => {
            inputValue.value = newValue;
            emit('update:modelValue', inputValue.value);
        };
        const updateInputValue = (newValue) => {
            handleUpdate(newValue);
            setDropdownVisibility(true);
        };

        // dropdown logic
        const dropdownVisibility = ref(false);
        const setDropdownVisibility = (visibility) => {
            dropdownVisibility.value = visibility;
        };
        const selectOption = (option) => {
            handleUpdate(option);
            emit('optionSelected');
            setDropdownVisibility(false);
        };
        const filteredOptions = computed(() => props.options && props.options.length && inputValue.value ? props.options.filter(option => option.toLowerCase().includes(inputValue.value.toLowerCase())) : []);

        return {
            inputValue,
            handleUpdate,
            selectOption,
            dropdownVisibility,
            updateInputValue,
            filteredOptions,
            slots
        };
    }

};
</script>

<style scoped>
@layer components {
  /* autocomplete variants */
  .autocomplete-default {
    .dropdown {
      @apply max-h-56 rounded-sm py-1;
    }
    .list-item {
      @apply cursor-default select-none relative text-black py-1 pl-3 pr-9 hover:bg-custom-gray-2;
    }
    .selected-item {
      @apply bg-custom-gray-2 font-semibold;
    }
  }

  /* label variants */
  .label {
    @apply block text-sm text-black mr-3.5 flex-shrink-0;
  }
  .label-w-46 {
    @apply block text-sm text-black mr-3.5 w-46 flex-shrink-0;
  }
}
</style>
